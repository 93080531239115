import moment from 'moment'
import FetchType from '../../util/fetch-types'
import cloneDeep from 'lodash/cloneDeep'
import * as VisitSelectors from '../selectors/visits'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { determineRequestNeediness } from './api'
import { setFetching } from '../slices/api'
import {
  putPendingVisit,
  putPendingVisitCount,
  putPendingVisitPageLoaded,
  putPendingVisits,
  putVisit,
  putVisitParticipants,
  putVisitInterpreter,
  clearVisitInterpreter,
  putVisitCount,
  putVisitPageLoaded,
  putVisits,
  upsertVisit,
  upsertPendingVisit,
} from '../slices/visits'
import { fetchProviderIfNeeded } from './providers'
import { subscribeToPendingVisitTopic } from './pusher'
import * as MemberSelectors from '../selectors/members'
import VisitStatus from '../../util/visit-status'
import { getSelectedCategory, getSelectedFilters } from 'redux/selectors/providerFilters'
import { trackClickEvent } from 'util/analytics'
import { clearFilters, clearSelectedCategory } from 'redux/slices/providerFilters'

export const PAGE_LIMIT = 25
export const SORT_BY_CREATED = { createdAt: -1 }
export const SORT_BY_SCHEDULED_ASC = { scheduledAt: 1 }
export const SORT_BY_SCHEDULED_DESC = { scheduledAt: -1 }

const shouldBeSubscribed = (pendingVisit) => {
  if (pendingVisit.status === VisitStatus.inProgress) return true
  return pendingVisit.status === VisitStatus.pending
}

function subscribeToPendingVisitsInProgress(data, dispatch) {
  data.forEach((pendingVisit) => {
    if (shouldBeSubscribed(pendingVisit)) {
      dispatch(subscribeToPendingVisitTopic(pendingVisit.pendingVisitId))
    }
  })
}

export const fetchVisits =
  ({ memberId, page = 0, limit }) =>
  async (dispatch, getState) => {
    dispatch(setFetching({ fetchType: FetchType.getVisits, isFetching: true }))
    const addPageToVisit = (visit) => ({ ...visit, page })
    let success = false
    const config = {
      params: {
        filter: {
          memberId: [memberId],
        },
        limit: limit || PAGE_LIMIT,
        page,
        sort: SORT_BY_CREATED,
      },
    }
    const latestPage = VisitSelectors.getVisitPage(memberId)(getState())

    const handleSuccess = async (data) => {
      const results = data.paginated.map(addPageToVisit)
      const providerIds = Object.keys(
        results.reduce((acc, visit) => {
          if (visit.providerId) acc[visit.providerId] = true
          return acc
        }, {}),
      )

      providerIds.forEach((providerId) => {
        if (providerId) dispatch(fetchProviderIfNeeded(providerId))
      })
      dispatch(putVisits({ visits: results }))
      await dispatch(putVisitCount({ memberId, count: data.meta.results }))
      if (!latestPage || latestPage < page) await dispatch(putVisitPageLoaded({ memberId, page }))
      success = true
    }
    await makeRequest(FetchType.getVisits, config, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchType.getVisits, isFetching: false }))
    return { success }
  }

export const fetchAllVisits = () => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.getAllVisits, isFetching: true }))
  let success = false
  const state = getState()
  const loggedInMemberId = MemberSelectors.getLoggedInMemberId(state)
  const dependentIds = MemberSelectors.getMemberDependentIds(loggedInMemberId)(state)
  const config = {
    params: {
      filter: {
        memberId: [loggedInMemberId, ...dependentIds],
      },
      limit: PAGE_LIMIT * 2,
      page: 0,
      sort: SORT_BY_CREATED,
    },
  }
  const handleSuccess = async (data) => {
    const results = data.paginated
    const providerIds = Object.keys(
      results.reduce((acc, visit) => {
        if (visit.providerId) acc[visit.providerId] = true
        return acc
      }, {}),
    )

    providerIds.forEach((providerId) => {
      if (providerId) dispatch(fetchProviderIfNeeded(providerId))
    })
    dispatch(putVisits({ visits: results }))
    success = true
  }
  await makeRequest(FetchType.getVisits, config, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getAllVisits, isFetching: false }))
  return { success }
}

export const fetchVisitsIfNeeded =
  ({ memberId, page = 0 }) =>
  async (dispatch, getState) => {
    const state = getState()
    const latestPage = VisitSelectors.getVisitPage(memberId)(state)
    if (!!latestPage && page < latestPage) {
      const lastFetch = VisitSelectors.getVisitPageLastFetch(memberId, page)(state)
      const fiveMinutesAgo = moment().subtract(5, 'minutes')
      if (!!lastFetch && fiveMinutesAgo.isBefore(lastFetch)) {
        if (page > 1) await dispatch(fetchVisitsIfNeeded({ memberId, page: page - 1 }))
        return
      }
    }
    const { success } = await dispatch(fetchVisits({ memberId, page }))
    if (success && page > 0) await dispatch(fetchVisitsIfNeeded({ memberId, page: page - 1 }))
  }

export const fetchVisit = (visitId, memberId) => async (dispatch) => {
  let success = false
  let result = null
  dispatch(setFetching({ fetchType: FetchType.getVisit, isFetching: true }))
  const params = { visitId, memberId }
  const handleSuccess = (data) => {
    dispatch(putVisit({ visit: data }))
    if (data.providerId) dispatch(fetchProviderIfNeeded(data.providerId))
    success = true
    result = data
  }
  await makeRequest(FetchType.getVisit, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getVisit, isFetching: false }))
  return { success, data: result }
}

export const fetchVisitIfNeeded = (visitId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.maybeGetVisit, isFetching: true }))

  dispatch(
    determineRequestNeediness({
      cacheTtlInMinutes: 5,
      // lastFetchSelector: VisitSelectors.getVisitsLastFetch(visitId),
      existingSelector: VisitSelectors.getVisit(visitId),
      fetchNext: fetchVisit(visitId),
    }),
  )

  dispatch(setFetching({ fetchType: FetchType.maybeGetVisit, isFetching: false }))
}

export const fetchPendingVisits =
  ({ memberId, page = 0 }) =>
  async (dispatch, getState) => {
    dispatch(setFetching({ fetchType: FetchType.getPendingVisits, isFetching: true }))
    const addPageToVisit = (visit) => ({ ...visit, page })
    let success = false
    const config = {
      params: {
        filter: {
          memberId,
          status: ['in-progress', 'pending'],
        },
        limit: PAGE_LIMIT,
        page,
        sort: SORT_BY_SCHEDULED_ASC,
      },
    }
    const completedConfig = {
      params: {
        filter: {
          memberId,
          status: ['cancelled', 'completed', 'in-review'],
        },
        limit: PAGE_LIMIT,
        page,
        sort: SORT_BY_SCHEDULED_DESC,
      },
    }
    const latestPage = VisitSelectors.getPendingVisitPage(memberId)(getState())

    const handleSuccess = async (data) => {
      const results = data.paginated.map(addPageToVisit)
      const providerIds = Object.keys(
        results.reduce((acc, visit) => {
          if (visit.providerId) acc[visit.providerId] = true
          return acc
        }, {}),
      )

      providerIds.forEach((providerId) => {
        if (providerId) dispatch(fetchProviderIfNeeded(providerId))
      })
      subscribeToPendingVisitsInProgress(results, dispatch)
      dispatch(putPendingVisits({ pendingVisits: results }))
      await dispatch(putPendingVisitCount({ memberId, count: data.meta.results }))
      if (!latestPage || latestPage < page) await dispatch(putPendingVisitPageLoaded({ memberId, page }))
      success = true
    }
    await makeRequest(FetchType.getPendingVisits, config, handleSuccess, handleRequestFailure(dispatch))
    await makeRequest(FetchType.getPendingVisits, completedConfig, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.getPendingVisits, isFetching: false }))
    return { success }
  }

const fetchVisitsByPendingVisitIds = (pendingVisitIds, memberIds) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getVisits, isFetching: true }))
  let success = false

  const config = {
    params: {
      filter: {
        pendingVisitId: pendingVisitIds,
        memberId: memberIds,
      },
      limit: PAGE_LIMIT * 3,
      page: 0,
      sort: SORT_BY_SCHEDULED_DESC,
    },
  }

  const handleSuccess = (data) => {
    dispatch(putVisits({ visits: data.paginated }))
    success = true
  }

  await makeRequest(FetchType.getVisits, config, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getVisits, isFetching: false }))

  return { success }
}

export const fetchCompletedPendingVisits = () => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.getPendingVisits, isFetching: true }))
  let success = false
  const state = getState()
  const loggedInMemberId = MemberSelectors.getLoggedInMemberId(state)
  const dependentIds = MemberSelectors.getMemberDependentIds(loggedInMemberId)(state)
  const config = {
    params: {
      filter: {
        memberId: [loggedInMemberId, ...dependentIds],
        status: ['completed'],
      },
      limit: PAGE_LIMIT * 2,
      page: 0,
      sort: SORT_BY_SCHEDULED_DESC,
    },
  }

  const handleSuccess = async (data) => {
    const results = data.paginated
    const providerIds = Object.keys(
      results.reduce((acc, visit) => {
        if (visit.providerId) acc[visit.providerId] = true
        return acc
      }, {}),
    )
    const pendingVisitIds = results.map((res) => res.pendingVisitId)

    await dispatch(fetchVisitsByPendingVisitIds(pendingVisitIds, [loggedInMemberId, ...dependentIds]))

    providerIds.forEach((providerId) => {
      if (providerId) dispatch(fetchProviderIfNeeded(providerId))
    })
    dispatch(putPendingVisits({ pendingVisits: results }))
    success = true
  }
  await makeRequest(FetchType.getPendingVisits, config, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.getPendingVisits, isFetching: false }))
  return { success }
}

export const fetchPendingVisitsIfNeeded =
  ({ memberId, page = 0 }) =>
  async (dispatch, getState) => {
    dispatch(setFetching({ fetchType: FetchType.maybeGetPendingVisits, isFetching: true }))
    const state = getState()
    const latestPage = VisitSelectors.getPendingVisitPage(memberId)(state)
    if (!!latestPage && page < latestPage) {
      const lastFetch = VisitSelectors.getPendingVisitPageLastFetch(memberId, page)(state)
      const fiveMinutesAgo = moment().subtract(5, 'minutes')
      if (!!lastFetch && fiveMinutesAgo.isBefore(lastFetch)) {
        // fresh data already exists;
        if (page > 1) await dispatch(fetchPendingVisitsIfNeeded({ memberId, page: page - 1 }))
        return
      }
    }
    const { success } = await dispatch(fetchPendingVisits({ memberId, page }))
    if (success && page > 0) await dispatch(fetchPendingVisitsIfNeeded({ memberId, page: page - 1 }))
    dispatch(setFetching({ fetchType: FetchType.maybeGetPendingVisits, isFetching: false }))
  }

export const fetchPendingVisit = (pendingVisitId, memberId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getPendingVisit, isFetching: true }))
  let success = true
  let result = null
  const params = { pendingVisitId, memberId }
  const handleSuccess = (data) => {
    const pendingVisit = cloneDeep(data)
    if (data.visit) {
      dispatch(putVisit({ visit: data.visit }))
      delete pendingVisit.visit
    }
    dispatch(putPendingVisit({ pendingVisit }))
    if (data.providerId) dispatch(fetchProviderIfNeeded(data.providerId))
    success = true
    result = data
  }
  await makeRequest(FetchType.getPendingVisit, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getPendingVisit, isFetching: false }))
  return { success, data: result }
}

export const fetchAndMergePendingVisit = (pendingVisitId, memberId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getPendingVisit, isFetching: true }))
  let success = true
  let result = null
  const params = { pendingVisitId, memberId }
  const handleSuccess = (data) => {
    const pendingVisit = cloneDeep(data)
    if (data.visit) {
      dispatch(upsertVisit({ visit: data.visit }))
      delete pendingVisit.visit
    }
    dispatch(upsertPendingVisit({ pendingVisit }))
    if (data.providerId) dispatch(fetchProviderIfNeeded(data.providerId))
    success = true
    result = data
  }
  await makeRequest(FetchType.getPendingVisit, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getPendingVisit, isFetching: false }))
  return { success, data: result }
}

export const fetchVisitByPendingVisit = (pendingVisitId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getVisitByPendingVisit, isFetching: true }))
  const params = { pendingVisitId }
  const handleSuccess = (data) => {
    dispatch(putVisit({ visit: data }))
    if (data.providerId) dispatch(fetchProviderIfNeeded(data.providerId))
  }
  await makeRequest(FetchType.getVisitByPendingVisit, params, handleSuccess, (error) => {
    console.error(error)
  })

  dispatch(setFetching({ fetchType: FetchType.getVisitByPendingVisit, isFetching: false }))
}

export const cancelVisit = (pendingVisitId, memberId, statusReason, statusReasonCode) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.updateCancelVisit, isFetching: true }))
  const params = { pendingVisitId, memberId, statusReason, statusReasonCode }
  let success = false
  const handleSuccess = (data) => {
    dispatch(putPendingVisit({ pendingVisit: data }))
    if (data && data.status === 'cancelled') {
      success = true
    }
  }
  await makeRequest(FetchType.updateCancelVisit, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.updateCancelVisit, isFetching: false }))
  return { success: success }
}

export const createPendingVisit = (pendingVisit) => async (dispatch, getState) => {
  dispatch(
    setFetching({
      fetchType: FetchType.addPendingVisit,
      isFetching: true,
    }),
  )
  const params = { pendingVisit, memberId: pendingVisit.memberId }
  let success = false
  let result = null
  const handleSuccess = (data) => {
    dispatch(subscribeToPendingVisitTopic(data.pendingVisitId))
    dispatch(putPendingVisit({ pendingVisit: data }))
    try {
      const Id = data?.pendingVisitId
      const state = getState()
      const filters = getSelectedFilters(state)
      const filteredCategory = getSelectedCategory(state)

      if (filters && filteredCategory && Id) {
        trackClickEvent(`Visit Confirmed with Filters`, {
          visitId: Id,
          providerFiltering: filters,
          providerCategorySelected: filteredCategory,
          device: 'web',
        })

        clearFilters()
        clearSelectedCategory()
      }
    } catch (e) {
      console.log(e, ' Unable to send Pendo filter data')
    }
    success = true
    result = data
  }
  await makeRequest(FetchType.addPendingVisit, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(
    setFetching({
      fetchType: FetchType.addPendingVisit,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const fetchVideoAccessToken = (memberId, visitId) => async (dispatch) => {
  let success = false
  let result = null
  const fetchType = FetchType.getVideoTokenV2

  dispatch(setFetching({ fetchType, isFetching: true }))
  const params = { memberId, visitId }

  const handleSuccess = (data) => {
    if (data) {
      success = true
      result = data
    }
  }
  await makeRequest(fetchType, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType, isFetching: false }))

  return { success, data: result }
}

export const addVisitParticipant = (email, visitId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.addVideoParticipant, isFetching: true }))
  const params = { email, visitId }
  let success = false
  let result = null
  const handleSuccess = (data) => {
    // data is a visit with the added participant
    if (data) {
      if (data.visitId && data.participants) {
        dispatch(
          putVisitParticipants({
            visitId: data.visitId,
            participants: data.participants,
          }),
        )
      }
      success = true
      result = data
    }
  }
  await makeRequest(FetchType.addVideoParticipant, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.addVideoParticipant, isFetching: false }))
  return { success: success, data: result }
}

export const addVisitInterpreter =
  ({ visitId, asl = false }) =>
  async (dispatch) => {
    const fetchType = FetchType.postAddDailyCallInterpreter
    dispatch(setFetching({ fetchType, isFetching: true }))
    let success = false
    let result = null
    const params = { visitId, asl }
    const handleSuccess = (data) => {
      dispatch(
        putVisitInterpreter({
          visitId: data.visitId,
          interpreter: data.interpreter,
          aslInterpreter: data.aslInterpreter,
        }),
      )
      success = true
      result = data
    }
    await makeRequest(fetchType, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType, isFetching: false }))
    return { success, data: result }
  }

export const removeVisitInterpreter =
  ({ visitId, interpreterId, asl = false }) =>
  async (dispatch) => {
    const fetchType = FetchType.putRemoveDailyCallInterpreter
    dispatch(setFetching({ fetchType, isFetching: true }))
    let success = false
    let result = null
    const params = { visitId, interpreterId, asl }
    const handleSuccess = (data) => {
      dispatch(clearVisitInterpreter({ visitId, asl }))
      success = true
      result = data
    }
    await makeRequest(fetchType, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType, isFetching: false }))
    return { success, data: result }
  }

export const getForms = (filter, token) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getForms, isFetching: true }))
  let result = []
  const headers = token ? { 'x-form-token': `Bearer ${token}` } : undefined
  const params = { params: { filter }, headers }
  const handleSuccess = (data) => {
    result = data
  }
  await makeRequest(FetchType.getForms, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.getForms, isFetching: false }))
  return result
}

export const updateVisitAssesmentResponse = (pendingVisitId, form) => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.updateVisitAssesmentResponse, isFetching: true }))
  let result = null
  const params = {
    pendingVisitId,
    intake: {
      intakeType: form.groupSection,
      form: {
        formId: form.formId,
        answers: form.answers,
      },
    },
  }
  const handleSuccess = (data) => {
    const memberId = getState().member.meta.loggedInMemberId
    dispatch(fetchPendingVisit(pendingVisitId, memberId))
    result = data
  }
  await makeRequest(FetchType.updateVisitAssesmentResponse, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.updateVisitAssesmentResponse, isFetching: false }))
  return result
}

export const fetchVisitSurveyStatus =
  ({ token }) =>
  async (dispatch) => {
    dispatch(setFetching({ fetchType: FetchType.getVisitSurveyStatus, isFetching: true }))

    let result = null

    const handleSuccess = (data) => {
      result = data
    }
    await makeRequest(FetchType.getVisitSurveyStatus, { token }, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.getVisitSurveyStatus, isFetching: false }))

    return result
  }

export const saveVisitSurveyAnswers = (formBase, pendingVisitId, token) => async (dispatch) => {
  const responseId = formBase.form.response?.responseId
  const fetchType = responseId ? FetchType.putVisitSurvey : FetchType.postVisitSurvey

  dispatch(setFetching({ fetchType, isFetching: true }))

  let result = null

  const params = {
    token,
    pendingVisitId,
    form: {
      formId: formBase.form.formId,
      answers: formBase.form.answers,
    },
  }

  if (responseId) {
    params.surveyId = responseId
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log({ saveVisitSurveyParams: params })
  }

  const handleSuccess = (data) => {
    result = data
    return data
  }

  await makeRequest(fetchType, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType, isFetching: false }))

  return result
}

export const fetchCareRecordUrl =
  ({ visitId }) =>
  async (dispatch) => {
    dispatch(setFetching({ fetchType: FetchType.postSignedUrls, isFetching: true }))
    let success = false
    let result = null
    const params = { parameters: { visitId }, resource: 'care_record' }
    const handleSuccess = (data) => {
      success = true
      result = data
    }
    await makeRequest(FetchType.postSignedUrls, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.postSignedUrls, isFetching: false }))

    return { success, data: result }
  }

export const fetchPendingVisitCalendarInfo = (memberId, pendingVisitId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getPendingVisitCalendarInfo, isFetching: true }))
  let success = false
  let result = null
  const params = { memberId, pendingVisitId }
  const handleSuccess = (data) => {
    success = true
    result = data
  }
  await makeRequest(FetchType.getPendingVisitCalendarInfo, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.getPendingVisitCalendarInfo, isFetching: false }))

  return { success, data: result }
}

export const postPendingVisitCalendarInfo = (memberId, pendingVisitId, type) => async (dispatch) => {
  const fetchType = FetchType.postPendingVisitCalendarInfo
  dispatch(setFetching({ fetchType, isFetching: true }))
  let success = false
  let result = null
  const params = { memberId, pendingVisitId, type }
  const handleSuccess = (data) => {
    success = true
    result = data
  }
  await makeRequest(fetchType, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType, isFetching: false }))
  return { success, data: result }
}
