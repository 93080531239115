import { QueryCache } from '@tanstack/react-query'
import { QueryClient } from '@tanstack/react-query'
import { toast } from 'util/toast'

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      toast.error(typeof error === 'string' ? error : 'There was an error making the request.')
    },
  }),
})
