import * as Yup from 'yup'
import catchApiFailure from 'api/requests/catch-api-error'
import ApiFactory, { ApiTypes } from 'api'
import configBuilder from 'api/requests/config-builder'
import { normalizeJsonToApi } from 'api/helpers'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  pendingVisitId: Yup.string().required(),
  type: Yup.string().required(),
})

export type Request = Yup.InferType<typeof RequestSchema>

export const isValid = async (params: Request) => await RequestSchema.isValid(params)
export const validate = async (params: Request) => RequestSchema.validate(params)
export const make = async (params: Request) =>
  normalizeJsonToApi({
    type: params.type,
  })
export const url = (params: Request) => `/v1/${params.memberId}/visits/pending/${params.pendingVisitId}/calendars`

export const execute = async (params: Request) =>
  // TODO: remove once configBuilder is typed
  //@ts-ignore
  api.post(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
