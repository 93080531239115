import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AddPendingVisitSceneChooser from '../../components/visit-creation/AddPendingVisitSceneChooser'
import Scenes from '../../util/Scenes'
import { ADD_PENDING_VISIT, createMedicalPath, createWithQuery, DASHBOARD, MEDICAL_WIZARD } from '../../routes/paths'
import VisitScheduleType from '../../util/visit-schedule-types'
import VisitTypes, { minorConsentPhase2Values, ServiceStatusTypes } from '../../util/visit-types'
import NewVisitSchema from '../../schemas/NewVisitSchema'
import AddVisitPageLayout from '../../layouts/AddVisitPageLayout'
import NoProvidersAvailable from '../../components/visit-creation/NoProvidersAvailable'
import TestIds from '../../util/TestIds'
import * as APM from 'util/apm'
import PendingVisit from '../../types/visit/pendingVisit'
import type { AnyAction, Dispatch } from 'redux'
import Member, { Phone, Service } from '../../types/member/member'
import Provider from '../../types/provider/provider'
import { useHistory } from 'react-router'
import { useQuery } from 'hooks/useQuery'
import NewVisitEntrySchema from 'schemas/NewVisitEntrySchema'
import AddPendingVisitRedirectModal from 'components/visit-creation/AddPendingVisitRedirectModal'
import { MemberSelectSchema } from 'schemas/MemberSelectSchema'
import { toast } from 'util/toast'
import { getMemberHistoryStatus } from 'redux/selectors/medical-history'
import { useDispatch, useSelector } from 'react-redux'
import { MEMBER_ID } from 'util/session-constants'
import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import { isUSLocation } from 'util/visit-location'
import { isMedicalWizardNeeded } from 'util/medical-wizard'
import useFeatureFlagToggle from 'components/feature-flag/useFeatureFlagToggle'
import { newVisitRoutingEntryCardType } from 'forms/visit-creation/NewVisitRoutingEntryForm/NewVisitRoutingEntryForm'
import { restrictedCountries } from 'util/countries'
import * as MemberSelectors from '../../redux/selectors/members'
import { TcFF } from 'components/feature-flag/flags'
import { sessionPersistence } from 'util/storage'
// import Languages from 'util/languages'

export const Steps = {
  addService: 0,
  memberSelect: 1,
  location: 2,
  locationState: 3,
  locationCountry: 4,
  visitType: 5,
  reason: 6,
  modality: 7,
  provider: 8,
  scheduling: 9,
  pharmacy: 10,
  payment: 11,
  confirmation: 12,
  noAvailableProviders: 13,
  routingEntry: 14,
  visitQuestions: 15,
  recommended: 16,
  callSupport: 17,
  provideConsent: 18,
} as const

const SCENE_STEPS = {
  [Steps.addService]: Scenes.newVisit.addService,
  [Steps.memberSelect]: Scenes.newVisit.memberSelect,
  [Steps.location]: Scenes.newVisit.location,
  [Steps.locationState]: Scenes.newVisit.locationState,
  [Steps.locationCountry]: Scenes.newVisit.locationCountry,
  [Steps.visitType]: Scenes.newVisit.visitType,
  [Steps.reason]: Scenes.newVisit.reason,
  // Property 'preference' commented out in 'Steps'
  // @ts-ignore
  [Steps.preference]: Scenes.newVisit.preference,
  [Steps.modality]: Scenes.newVisit.modality,
  [Steps.provider]: Scenes.newVisit.providerList,
  [Steps.scheduling]: Scenes.newVisit.scheduling,
  [Steps.pharmacy]: Scenes.newVisit.pharmacy,
  [Steps.payment]: Scenes.newVisit.payment,
  [Steps.confirmation]: Scenes.newVisit.confirmation,
  [Steps.noAvailableProviders]: Scenes.newVisit.noAvailableProviders,
  [Steps.routingEntry]: Scenes.newVisit.engineEntry,
  [Steps.visitQuestions]: Scenes.newVisit.visitQuestions,
  [Steps.recommended]: Scenes.newVisit.recommended,
  [Steps.callSupport]: Scenes.newVisit.callSupport,
  [Steps.provideConsent]: Scenes.newVisit.provideConsent,
} as const

export const isBeforeVisitTypeSelection = (step: number) => step < Steps.visitType
export const isRoutingEntryStep = (step: number) => step === Steps.routingEntry
export const isSelectMemberStep = (step: number) => step === Steps.memberSelect
const isLocationStep = (step: number) => !isSelectMemberStep(step) && step < Steps.visitType

const isPharmacyRequired = (visitType) => visitType === VisitTypes.psychiatry || visitType === VisitTypes.medical

type AddPendingVisitPageProps = {
  member: Member
  provider: Provider
  memberId: string | null
  pendingVisit: PendingVisit
  isLoading: boolean
  hasClinicalService: boolean
  addPhoneNumber: (phone: Phone) => Promise<
    | ((
        dispatch: Dispatch,
        getState: () => any,
      ) => Promise<{
        success: boolean
        data: Member | null
      }>)
    | null
  >
  clearPayload: () => void
  updatePayload: (payload: any) => void
  createPendingVisit: (payload: any) => Promise<
    (dispatch: Dispatch) => Promise<{
      success: boolean
      data: null
    }>
  >
  checkProviderAvailability: (
    location: string,
    visitType: string,
  ) => Promise<
    (
      dispatch: Dispatch,
      getState: any,
    ) => Promise<{
      success: boolean
      count: number
    }>
  >
  fetchVisitCount: (visitType: string) => Promise<
    (dispatch: Dispatch) => Promise<{
      success: boolean
      count: null
    }>
  >
  selectedService: Service
  fetchAvailableProviders: (filters: any) => Promise<
    | ((dispatch: Dispatch) => Promise<{
        success: boolean
        data: null
      }>)
    | null
  >
  addServiceCode: (accessCode: string) => Promise<(dispatch: Dispatch<AnyAction>) => Promise<{ success: boolean }>>
  pendingScheduledVisits: any
  userMemberId: string
  removeKeysAndUpdate: (payload: any, keysToRemove: string[]) => void
}

const AddPendingVisitPage = (props: AddPendingVisitPageProps) => {
  const [isConfirmCancelDialogVisible, setIsConfirmCancelDialogVisible] = useState(false)
  const [isNoProvidersAvailableDialogVisible, setIsNoProvidersAvailableDialogVisible] = useState(false)

  const {
    pendingVisit,
    clearPayload,
    updatePayload,
    createPendingVisit,
    fetchVisitCount,
    selectedService,
    addServiceCode,
    hasClinicalService,
    removeKeysAndUpdate,
  } = props

  const query = useQuery(['step', 'redirect', 'cardType', 'throughStep'])
  const dispatch = useDispatch()
  const redirectModalRef = useRef<ModalRef | null>(null)
  const memberId = sessionPersistence.get(MEMBER_ID)
  const medicalHistoryStatus = useSelector(getMemberHistoryStatus(memberId))
  const memberData = useSelector(MemberSelectors.getMember(memberId))
  const medicalHistoryCompleted = useMemo(
    () => medicalHistoryStatus && !Object.values(medicalHistoryStatus).filter((status) => !status).length,
    [medicalHistoryStatus],
  )
  const isServiceLineRoutingFlagOn = useFeatureFlagToggle('tcServiceLineRouting')
  const isAllowDependentsFlagOn = useFeatureFlagToggle(TcFF.tcAllowDependents)
  const tcMinorConsentPhase2 = useFeatureFlagToggle(TcFF.tcMinorConsentPhase2)
  const memberAge = useSelector(MemberSelectors.getMemberAge(pendingVisit?.memberId))
  const isUserMinor = memberAge < 18

  const isTherapyNowOrHC =
    (pendingVisit.visitType === VisitTypes.therapy && pendingVisit.scheduledType === 'now') ||
    pendingVisit.visitType === VisitTypes.healthCoach

  const history = useHistory()

  const { visitType } = pendingVisit

  const step = useMemo(() => {
    const step = Number(query?.step)
    if (Number.isNaN(step)) return Steps.memberSelect
    return step
  }, [query?.step])

  const cardType = useMemo(() => {
    return query?.cardType
  }, [query?.cardType])

  const params = useMemo(() => {
    const param = {}
    if (cardType) {
      param['cardType'] = cardType
    }
    if (query?.redirect) {
      param['redirect'] = query?.redirect
    }
    if (query?.throughStep) {
      param['throughStep'] = query?.throughStep
    }
    return param
  }, [cardType, query])

  const scene = useMemo(() => {
    if (!step && step !== Steps.addService) {
      return Scenes.newVisit.memberSelect
    }
    return SCENE_STEPS[step]
  }, [step])

  const setupDefaultUser = async () => {
    const member = {
      memberDob: memberData?.dob || '',
      memberFirstName: memberData?.firstName || '',
      memberId: memberData.memberId || '',
      memberLastName: memberData?.lastName || '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    updatePayload(member)
    history.push(
      createWithQuery(ADD_PENDING_VISIT, {
        step: Steps.location,
        ...params,
      }),
    )
  }

  const navigateHome = useCallback(() => {
    isServiceLineRoutingFlagOn && clearPayload()
    history.push(DASHBOARD)
  }, [clearPayload, history, isServiceLineRoutingFlagOn])

  const startOver = useCallback(() => {
    isServiceLineRoutingFlagOn && clearPayload()
    setIsNoProvidersAvailableDialogVisible(false)
    history.push(
      createWithQuery(ADD_PENDING_VISIT, {
        step: isServiceLineRoutingFlagOn ? Steps.routingEntry : Steps.memberSelect,
      }),
    )
  }, [clearPayload, history, isServiceLineRoutingFlagOn])

  useEffect(() => {
    if (query?.redirect) {
      redirectModalRef.current?.open()
    }
  }, [query])

  const handleSlrLocationHardStop = async () => {
    const newValues = {
      location: '',
      locationCountry: '',
    }
    await updatePayload(newValues)
    history.push(
      createWithQuery(ADD_PENDING_VISIT, {
        step: Steps.visitType,
        ...params,
      }),
    )
  }

  const shouldRedirectToCallSupport = useMemo((): boolean => {
    const { status, visitType, isParentConsentGranted, isSelfConsentGranted } = pendingVisit
    const isStatusDisabled = status === ServiceStatusTypes.disabled
    const isStatusConditionallyEnabled = status === ServiceStatusTypes.conditionally_enabled
    const isCounseling = visitType === VisitTypes.therapy

    if (isStatusConditionallyEnabled) {
      // If parental consent has already been granted, we let the user continue with the regular scheduling flow.
      if (isParentConsentGranted) return false
      // If parental consent or self-consent has already been granted and the service is Counseling, we let the user continue with the current scheduling flow.
      if (isCounseling && (isParentConsentGranted || isSelfConsentGranted)) return false
      // If parental consent is required and not given, redirect to call support
      return true
    }
    // If the status is disabled, redirect to 'callSupport' step.
    return isStatusDisabled
  }, [pendingVisit])

  useEffect(() => {
    const isSelectMemberInvalid = !isSelectMemberStep(step) && !MemberSelectSchema.isValidSync(pendingVisit)
    const isLocationInvalid = !isLocationStep(step) && !NewVisitEntrySchema.isValidSync(pendingVisit)
    if (!hasClinicalService) {
      history.push(
        createWithQuery(ADD_PENDING_VISIT, {
          step: Steps.addService,
          redirect: query?.redirect ? query?.redirect : null,
        }),
      )
      return
    }

    // Take user to callSupport step if necessary
    if (
      step === Steps.reason &&
      !isTherapyNowOrHC &&
      tcMinorConsentPhase2 &&
      isUserMinor &&
      !isServiceLineRoutingFlagOn &&
      !pendingVisit.selfConsentConfirmationId
    ) {
      if (
        pendingVisit.selfConsentPreApprovalId &&
        !pendingVisit.isSelfConsentGranted &&
        !pendingVisit.isParentConsentGranted
      ) {
        history.push(
          createWithQuery(ADD_PENDING_VISIT, {
            step: Steps.provideConsent,
            ...params,
          }),
        )
        return
      }
      if (shouldRedirectToCallSupport) {
        history.push(
          createWithQuery(ADD_PENDING_VISIT, {
            step: Steps.callSupport,
            ...params,
          }),
        )
        return
      }
    }

    // Avoid Select Member if dependant feature is off
    if (!isAllowDependentsFlagOn && step === Steps.memberSelect) {
      setupDefaultUser()
      history.push(
        createWithQuery(ADD_PENDING_VISIT, {
          step: Steps.location,
          ...params,
        }),
      )
      return
    }

    if (isServiceLineRoutingFlagOn) {
      if (step === Steps.addService || step === Steps.routingEntry) {
        history.push(
          createWithQuery(ADD_PENDING_VISIT, {
            step: Steps.routingEntry,
            redirect: query?.redirect || null,
          }),
        )
      }
      return
    }
    if (isLocationInvalid || isSelectMemberInvalid) {
      history.push(
        createWithQuery(ADD_PENDING_VISIT, {
          step: Steps.memberSelect,
          ...params,
        }),
      )
    }
  }, [
    history,
    pendingVisit,
    step,
    hasClinicalService,
    isTherapyNowOrHC,
    tcMinorConsentPhase2,
    isUserMinor,
    isServiceLineRoutingFlagOn,
    isAllowDependentsFlagOn,
    shouldRedirectToCallSupport,
  ])

  useEffect(() => {
    if (scene === Scenes.newVisit.noAvailableProviders) {
      setIsNoProvidersAvailableDialogVisible(true)
    }
  }, [scene])

  const getNextStep = useCallback(
    (overLimit = false) => {
      // TODO preferences -> replace first cond with step > Steps.preferences
      if (step > Steps.reason && step < Steps.pharmacy) {
        if (pendingVisit.scheduledType === VisitScheduleType.scheduled) {
          if (step < Steps.scheduling) {
            return step + 1
          }
        }

        if (isPharmacyRequired(pendingVisit.visitType)) {
          return Steps.pharmacy
        } else if (!pendingVisit.price && !overLimit) {
          return Steps.confirmation // skip payment screen
        } else {
          return Steps.payment // no pharmacy selection, but show payment screen
        }
      }
      if (step === Steps.pharmacy && !pendingVisit.price) {
        return Steps.confirmation // skip the payment screen
      }
      if (step === Steps.reason && pendingVisit.scheduledType !== VisitScheduleType.now) {
        return Steps.modality
      }
      if (step === Steps.provideConsent && pendingVisit.selfConsentPreApprovalId) {
        return Steps.reason
      }
      return step + 1
    },
    [step, pendingVisit],
  )

  const handleCancel = useCallback(() => {
    clearPayload()
    history.push(DASHBOARD)
  }, [clearPayload, history])

  const navigateToMedicalWizard = () => {
    history.push(createMedicalPath(MEDICAL_WIZARD, memberId), {
      params: {
        step,
        ...params,
      },
    })
  }

  const handleSceneSubmit = useCallback(
    async (values: any, _: any) => {
      try {
        if (Scenes.newVisit.engineEntry === scene) {
          switch (values.visitType) {
            case VisitTypes.psychiatry:
              await updatePayload(values)
              history.push(
                createWithQuery(ADD_PENDING_VISIT, {
                  step: Steps.memberSelect,
                }),
              )
              break
            case newVisitRoutingEntryCardType.medicalAndHC:
              history.push(
                createWithQuery(ADD_PENDING_VISIT, {
                  step: Steps.visitType,
                  cardType: newVisitRoutingEntryCardType.medicalAndHC,
                }),
              )
              break
            case newVisitRoutingEntryCardType.mentalHealth:
              clearPayload()
              history.push(
                createWithQuery(ADD_PENDING_VISIT, {
                  step: Steps.visitQuestions,
                  cardType: newVisitRoutingEntryCardType.mentalHealth,
                }),
              )
              break
          }
        } else if (Scenes.newVisit.confirmation === scene) {
          const payload = NewVisitSchema.cast(values)
          const promise = createPendingVisit(payload)
          // @ts-ignore
          const { success } = await promise
          if (success) {
            toast.success(
              <p role="alert" style={{ lineHeight: '18px', margin: '5px' }}>{`Visit has been created successfully`}</p>,
            )
            history.push(DASHBOARD)
            clearPayload()
          } else {
            console.error('error adding visit')
          }
        } else if (Scenes.newVisit.scheduling === scene || Scenes.newVisit.pharmacy === scene) {
          let isOverLimit = false
          if (selectedService.limitMember && selectedService.overLimitFee) {
            // @ts-ignore
            const { count, success } = await fetchVisitCount(selectedService.serviceType)
            if (success && count >= selectedService.limitMember) isOverLimit = true
          }
          if (isOverLimit) {
            await updatePayload({
              ...values,
              price: selectedService.overLimitFee,
            })
          } else {
            await updatePayload(values)
          }
          history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: getNextStep(isOverLimit),
              ...params,
            }),
            { isOverLimit },
          )
        } else if (Scenes.newVisit.location === scene) {
          const newValues = {
            location: '',
            locationCountry: values.isOutsideUS === 'true' ? '' : 'US',
          }
          await updatePayload(newValues)
          history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: isUSLocation(newValues.locationCountry) ? Steps.locationState : Steps.locationCountry,
              ...params,
            }),
          )
        } else if (Scenes.newVisit.locationState === scene || Scenes.newVisit.locationCountry === scene) {
          const newValues = {
            location: Scenes.newVisit.locationState === scene ? values.location : '',
            locationCountry: Scenes.newVisit.locationCountry === scene ? values.locationCountry : 'US',
          }
          await updatePayload(newValues)
          if (isServiceLineRoutingFlagOn && restrictedCountries.includes(newValues.locationCountry)) {
            history.push(
              createWithQuery(ADD_PENDING_VISIT, {
                step: Steps.visitType,
                isRestrictedCountry: true,
                ...params,
              }),
            )
            return
          }
          if (visitType && isMedicalWizardNeeded(values, Scenes.newVisit.visitType, medicalHistoryCompleted)) {
            navigateToMedicalWizard()
            return
          }
          if (tcMinorConsentPhase2 && isUserMinor && !isServiceLineRoutingFlagOn) {
            await updatePayload({ ...newValues, visitType: '' })
            history.push(
              createWithQuery(ADD_PENDING_VISIT, {
                step: Steps.visitType,
                ...params,
              }),
            )
            return
          }
          history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: isServiceLineRoutingFlagOn && visitType ? Steps.reason : Steps.visitType,
              ...params,
            }),
          )
        } else if (Scenes.newVisit.visitType === scene && isServiceLineRoutingFlagOn) {
          await updatePayload(values)
          return history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: Steps.memberSelect,
              ...params,
            }),
          )
        } else if (isMedicalWizardNeeded(values, scene, medicalHistoryCompleted)) {
          await updatePayload(values)
          navigateToMedicalWizard()
        } else if (Scenes.newVisit.addService === scene) {
          await addServiceCode(values.code)
          history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: getNextStep(),
              ...params,
            }),
          )
          // TODO - handle preferences scene
          // } else if (Scenes.newVisit.preference === scene) {
          //   const preferences = values?.preferences
          //     ? {
          //         ...values?.preferences,
          //         languages: (values.preferences.languages || []).map((abbrev) => ({ abbrev, name: Languages[abbrev] })),
          //       }
          //     : {
          //         gender: '',
          //         ethnicity: [],
          //         specialty: [],
          //         languages: [],
          //       }
        } else if (Scenes.newVisit.recommended === scene) {
          if (values.navigateTo) {
            await clearPayload()
            history.push(values.navigateTo)
            return
          }
          delete values.navigateTo
          await updatePayload(values)
          history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: Steps.memberSelect,
              ...params,
            }),
          )
        } else if (Scenes.newVisit.visitQuestions === scene) {
          history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: getNextStep(),
              ...params,
            }),
          )
        } else if (Scenes.newVisit.provideConsent === scene) {
          history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: getNextStep(),
              ...params,
            }),
          )
        } else if (Scenes.newVisit.reason === scene) {
          await removeKeysAndUpdate({ ...pendingVisit, ...values }, minorConsentPhase2Values)

          history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: getNextStep(),
              ...params,
            }),
          )
        } else {
          await updatePayload(values)
          history.push(
            createWithQuery(ADD_PENDING_VISIT, {
              step: getNextStep(),
              ...params,
            }),
          )
        }
      } catch (e) {
        console.error(e)
        APM.captureException(e)
      }
    },
    [scene, updatePayload, getNextStep, step, history],
  )

  const toggleConfirmCancelDialog = useCallback((_visible) => () => setIsConfirmCancelDialogVisible(!!_visible), [])

  return (
    <AddVisitPageLayout step={step}>
      <AddPendingVisitSceneChooser
        {...props}
        scene={scene}
        onCancel={toggleConfirmCancelDialog(true)}
        onSubmit={handleSceneSubmit}
        navigateHome={navigateHome}
        clearPayload={clearPayload}
        isServiceLineRoutingFlagOn={isServiceLineRoutingFlagOn}
        handleSlrLocationHardStop={handleSlrLocationHardStop}
      />
      {!!isConfirmCancelDialogVisible && (
        <Modal
          label="Cancel visit"
          opened
          onClose={toggleConfirmCancelDialog(false)}
          footer={({ close }) => (
            <>
              <button type="button" className="btn btn-neutral-outlined" onClick={close}>
                NO
              </button>
              <button type="button" className="btn btn-neutral" onClick={handleCancel}>
                Yes, cancel visit
              </button>
            </>
          )}
          data-test-id={TestIds.newVisit.view.cancelVisit}
        >
          <p className="typography-body-l">Are you sure you want to cancel this visit?</p>
        </Modal>
      )}
      {!!isNoProvidersAvailableDialogVisible && (
        <Modal
          label="No providers available"
          opened
          onClose={navigateHome}
          footer={({ close }) => (
            <>
              <button type="button" className="btn btn-neutral-outlined" onClick={close}>
                Close
              </button>
              <button type="button" className="btn btn-neutral" onClick={startOver}>
                Start Over
              </button>
            </>
          )}
        >
          <NoProvidersAvailable />
        </Modal>
      )}
      {query?.redirect && <AddPendingVisitRedirectModal ref={redirectModalRef} queryValue={query.redirect} />}
    </AddVisitPageLayout>
  )
}

export default AddPendingVisitPage
