import { Language } from 'types/provider/provider'
import { ServiceTypes } from 'util/visit-types'

export type Address = {
  id: string
  preferred: boolean
  street1: string
  street2?: string
  city: string
  state: string
  country: string
  zip: string
  addressType: string
}

export type Phone = {
  id: string
  preferred: boolean
  number: string
  type: string
  acceptSms: boolean
  acceptCallFlows: boolean
  extension?: string
}

export type MemberProvider = {
  id: string
  name: string
  providerType: string
  phones: Phone[]
  specialty: string
  suffix: string[]
  shareRecords: boolean
}

export type MemberSettingsType = {
  uniqueId: string
  userType: string
  userId: string
  acceptCallFlows: boolean
}

export const UTILITY_TYPES = {
  clinical: 'clinical',
  nonClinical: 'non_clinical',
  utility: 'utility',
} as const

type UtilityType = (typeof UTILITY_TYPES)[keyof typeof UTILITY_TYPES]

export type Service = {
  serviceId: string
  accessCode?: string | null
  limitAnniversaryAt?: string
  limitGroup?: number
  limitMember?: number | null
  overLimitFee?: number
  hardStop?: boolean
  serviceFee: number
  serviceType: ServiceTypes
  active?: boolean
  appliedAt?: string
  disabledAt?: string
  utilityType?: UtilityType
  status?: string | null
}

export type Acknowledgments = {
  [key: string]: {
    showPrompt: boolean
  }
}

export type MemberService = Service & {
  _id: string
}

export type TermsAndConditions = {
  id: string
  version: string
  versionType?: string
  type: string
}

export enum Gender {
  M = 'M',
  F = 'F',
  X = 'X',
}

export type Dependent = {
  memberId: string
  groupId: string
  clientId: string
  displayName: string
  firstName: string
  lastName: string
  firstNamePreferred?: string
  dob: string
  gender: keyof typeof Gender
  genderIdentity?: string | null
  phones: Phone[]
  addresses: Address[]
  memberProviders?: MemberProvider[]
  surveys: any[] // TODO Create survey type
  primaryMemberId: string
  relationType: string
  preferredLanguage?: Language
}

export type AvatarColors = {
  id: string
  alias: string
  border: string
  background: string
  aliasNumber: number
}

export type MeasurementIntakeOpt = {
  id: string
  optedOut: boolean
  optedBy: string
  optedAt: string
  optedById: string
}

export type MemberStatus = 'eligible' | 'registered' | 'ineligible' | 'deactivated' | 'locked' | 'dependent'

type Member = Omit<Dependent, 'primaryMemberId' | 'relationType'> & {
  rxVendorId: string | null
  userId: string
  externalId?: string
  communityAlias: string
  ethnicity: string[]
  email: string
  avatarColors: AvatarColors
  status: MemberStatus
  services: Service[]
  consentFilename: string | null
  dependents: Dependent[] | null
  termsConditions?: TermsAndConditions[]
  careRecordOptOut: boolean
  monitored: boolean
  createdAt: string
  registeredAt: string
  updatedAt: string
  publicKey: string
  graduationYear?: string
  graduationSemester?: string
  degreeClassification?: string
  genderSelfSelect?: string
  pronoun?: string
  measurementIntakeOpt?: MeasurementIntakeOpt
  phone?: Phone
}

export default Member
