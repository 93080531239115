import { useCallback, useEffect, useMemo, useRef } from 'react'
import withForm from 'hoc/withForm'
import VisitConfirmationChooser from 'components/visit-creation/VisitConfirmationChooser'
import TestIds from 'util/TestIds'
import ServiceInfo from 'components/visit-creation/ServiceInfo'
import VisitModality from 'components/visit-creation/VisitModality'
import VisitConfirmationIllustrationUrl from 'assets/images/visit_confirmation_illustration.svg'
import VisitTypes, { getActualVisitType } from 'util/visit-types'
import { AnyObjectSchema } from 'yup'
import type PendingVisit from 'types/visit/pendingVisit'
import { isSuccess } from 'util/helpers'
import VisitWarningModal from 'components/dashboard/VisitWarningModal/VisitWarningModal'
import { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import useFeatureFlagToggle from 'components/feature-flag/useFeatureFlagToggle'
import { TcFF } from 'components/feature-flag/flags'

type VisitConfirmationFormProps = {
  initialValues: PendingVisit
  schema: AnyObjectSchema
  setFieldValue: () => void
  submitForm: () => void
  handleSubmit: () => void
  values: PendingVisit
  errors: object
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading?: boolean
  children: (props?: { disabled: boolean; submitText: string }) => React.ReactNode
}

const VisitConfirmationForm = (props: VisitConfirmationFormProps) => {
  const { handleSubmit, values } = props

  const warningModalRef = useRef<ModalRef | null>(null)
  const visitType = useMemo(
    () => getActualVisitType(values.visitType, values.scheduledType),
    [values.visitType, values.scheduledType],
  )

  const isDisabledConfirmFlagOn = useFeatureFlagToggle(TcFF.tcDisabledConfirm)

  useEffect(() => {
    if (visitType === VisitTypes.therapy) {
      warningModalRef.current?.open()
    }
  }, [visitType])

  const onDismiss = useCallback(() => {
    warningModalRef.current?.close()
  }, [])

  const displayPrice = useMemo(() => {
    if (!values?.price) return ''
    return `${Number(values.price).toFixed(2)}`
  }, [values])

  const successVisit = useMemo(() => {
    return isSuccess(visitType)
  }, [visitType])

  return (
    <form onSubmit={handleSubmit} className="flex w-full flex-1 flex-col">
      <div className="grid max-w-[750px] grid-cols-1 gap-x-4 pb-4 xl:grid-cols-[360px_1fr]">
        <div>
          <h2
            className="typography-body-xl mb-1 font-medium text-neutral-800"
            data-testid={TestIds.newVisit.label.confirmTitle}
          >
            {`Confirm your ${!successVisit ? 'visit' : 'session'}`}
          </h2>
          <div className="typography-body-l mb-4 font-medium text-neutral-600">Here are the details</div>
          <ServiceInfo type={visitType} className="mb-6" testId={TestIds.newVisit.label.confirmSubtitle} confirmation />
          <VisitModality modality={values.modality} className="mb-4" />
          {!!displayPrice && (
            <div className="bg-secondary-200 mb-4 rounded-xl px-6 py-4">
              <div className="typography-body-xl mb-4 font-medium text-neutral-800">Total cost</div>
              <div className="typography-h3 mb-4 text-neutral-800">${displayPrice}</div>
              <div className="typography-body text-neutral-600">You will be charged at the time of your visit. </div>
            </div>
          )}
          <VisitConfirmationChooser {...props} pendingVisit={values} />
        </div>
        <div className="hidden justify-center xl:flex ">
          <img src={VisitConfirmationIllustrationUrl} className="h-[280px] max-h-full w-full" alt="" />
        </div>
      </div>
      {props.children({ disabled: isDisabledConfirmFlagOn, submitText: 'Confirm' })}
      <VisitWarningModal ref={warningModalRef} onDismiss={onDismiss} />
    </form>
  )
}

export default withForm(VisitConfirmationForm)
