export const TcFF = {
  tcAllowDependents: 'tcAllowDependents',
  tcPsychScheduling30DayLimit: 'tcPsychScheduling30DayLimit',
  tcMinorConsentPhase2: 'tcMinorConsentPhase2',
  tcStudentSuccessContent: 'tcStudentSuccessContent',
  tcSuccessEntryTerminology: 'tcSuccessEntryTerminology',
  tcServiceLineRouting: 'tcServiceLineRouting',
  tcAddToCalendar: 'tcAddToCalendar',
  tcPhoneOptIn: 'tcPhoneOptIn',
  tcDisabledConfirm: 'tcDisabledConfirm',
}
