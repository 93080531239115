import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk'
import Loading from '@ui-components-3/ui/lib/components/Loading'
import AngleRightIcon from '@ui-components-3/icons/lib/regular/AngleRight'
import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import { createPortal } from 'react-dom'

type AddPaymentMethodDialogProps = {
  addErrorRole: () => void
  removeErrorRole: () => void
  paymentFormProps: object
}

const AddPaymentMethodDialog = forwardRef(
  ({ paymentFormProps, addErrorRole, removeErrorRole }: AddPaymentMethodDialogProps, ref: ForwardedRef<ModalRef>) => {
    const [isVisible, setIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const modalRef = useRef<ModalRef>(null)
    const footerRef = useRef<HTMLDivElement>(null)

    const hideLoading = useCallback(() => {
      setIsLoading(false)
    }, [setIsLoading])

    const toggleModal = useCallback(
      (_isVisible: boolean) => () => {
        if (_isVisible) {
          modalRef.current?.open()
        } else {
          modalRef.current?.close()
        }
        setIsVisible(_isVisible)
      },
      [],
    )

    const onClose = useCallback(() => {
      setIsVisible(false)
      setIsLoading(true)
      setTimeout(() => {
        // Forces the form to re-render after closing
        setIsVisible(true)
      }, 0)
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        open: toggleModal(true),
        close: toggleModal(false),
        softClose: toggleModal(false),
      }),
      [toggleModal],
    )

    return (
      <Modal
        label="Add New Card"
        size="m"
        footer={({ close }) => (
          <div className="grid grid-cols-2 gap-4" ref={footerRef}>
            <button type="button" className="btn btn-neutral-outlined" onClick={close}>
              Close
            </button>
          </div>
        )}
        ref={modalRef}
        onClose={onClose}
      >
        {!!isVisible && (
          <div className="relative min-h-[130px] flex-col">
            {!!isLoading && (
              <div className="absolute left-0 top-0 flex w-full items-center justify-center">
                <Loading />
              </div>
            )}
            <PaymentForm
              formId={'card-form'}
              paymentFormLoaded={hideLoading}
              {...paymentFormProps}
              formProps={{
                className: 'flex flex-col flex-1 justify-between bg-white',
              }}
            >
              <CreditCard
                text="Add Card"
                className="w-full"
                render={(Button) => {
                  return createPortal(
                    <Button className="btn btn-neutral">
                      Next
                      <AngleRightIcon className="h-4 w-4" aria-hidden="true" />
                    </Button>,
                    footerRef.current,
                  )
                }}
                errorClassAdded={addErrorRole}
                errorClassRemoved={removeErrorRole}
              />
            </PaymentForm>
          </div>
        )}
      </Modal>
    )
  },
)

export default AddPaymentMethodDialog
