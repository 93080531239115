import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
})

type Request = Yup.InferType<typeof RequestSchema>

export const isValid = async (params: Request) => await RequestSchema.isValid(params)
export const validate = async (params: Request) => RequestSchema.validate(params)
export const url = (params: Request) => `/v1/user-settings/${params.memberId}`
// TODO: remove once configBuilder is typed
//@ts-ignore
export const execute = async (params: Request) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
