import { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import routeTree from '../index'
import RouteBranch from 'routes/RouteBranch'
import ScrollToTop from 'core/ScrollToTop'
import withPusher from 'hoc/withPusher'
import A11yMessage from 'components/common/A11yMessage'
import Loading from '@ui-components-3/ui/lib/components/Loading'
import * as paths from '../paths'

const ConnectedAlert = lazy(() => import('core/ConnectedAlert'))
const PushService = lazy(() => import('core/PushService'))
const ContentPlaygroundPage = lazy(() => import('content-playground/pages/ContentPlaygroundPage'))

const Root = (pusherProps) => {
  return (
    <>
      <A11yMessage />
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path={paths.CONTENT_PLAYGROUND}
          render={() => (
            <Suspense fallback={<Loading variant="big" />}>
              <ContentPlaygroundPage />
            </Suspense>
          )}
        />

        {routeTree.map(({ path, ...route }) => {
          if (path === paths.CONTENT_PLAYGROUND) {
            console.log('Skipping ContentPlaygroundPage in routeTree as it has a direct route')
            return null
          }
          return <RouteBranch key={path} path={path} {...pusherProps} {...route} />
        })}
      </Switch>
      <Suspense fallback={<Loading variant="big" />}>
        <PushService />
        <ConnectedAlert />
      </Suspense>
    </>
  )
}

export default withPusher(Root)
