import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from 'api/helpers'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  userId: Yup.string().required(),
  userType: Yup.string().required(),
  acceptCallFlows: Yup.bool().required(),
})

export type Request = Yup.InferType<typeof RequestSchema>

export const isValid = async (params: Request) => {
  return await RequestSchema.isValid(params)
}
export const validate = async (params: Request) => RequestSchema.validate(params)

export const make = async (params: Request) => normalizeJsonToApi(params)

export const url = () => `/v1/user-settings`
export const execute = async (params: Request) => api.post(url(), await make(params)).catch(catchApiFailure)
