import * as Yup from 'yup'
export const SlimPhoneSchema = Yup.object().shape({
  type: Yup.string()
    .label('Phone Type')
    .oneOf(['mobile', 'home', 'office', 'fax'], 'Must be one of Mobile, Home, or Office')
    .required(),
  number: Yup.string()
    .label('Phone Number')
    .matches(/^(\d{10})$/, 'Phone number must have 10 digits')
    .required(),
  id: Yup.string().strip(),
  _id: Yup.string().strip(),
  acceptSms: Yup.boolean().strip(),
  preferred: Yup.boolean().strip(),
})
const PhoneSchema = Yup.object().shape({
  type: Yup.string()
    .label('Phone Type')
    .oneOf(['mobile', 'home', 'office', 'fax'], 'Must be one of Mobile, Home, or Office')
    .required('Error: Phone Type is a required field that cannot be left blank'),
  number: Yup.string()
    .label('Phone Number')
    .matches(/^(\d{10})$/, 'Phone number must have 10 digits')
    .required('Error: Phone Number is a required field that cannot be left blank'),
  acceptSms: Yup.boolean().label('Allow SMS messaging to this number').nullable().notRequired(),
  acceptCallFlows: Yup.boolean().label('Allow phone calls from TimelyCare').nullable().notRequired(),
  preferred: Yup.boolean().nullable().notRequired(),
})

export type PhoneFormValues = Yup.InferType<typeof PhoneSchema>

export default PhoneSchema
