import VisitTypes, { ServiceTypes } from './visit-types'
import PhoneIcon from '@ui-components-3/icons/lib/regular/Phone'
import VideoIcon from '@ui-components-3/icons/lib/regular/Video'
import ChatIcon from '@ui-components-3/icons/lib/regular/Message'
import PhoneIconLight from '@ui-components-3/icons/lib/light/Phone'
import VideoIconLight from '@ui-components-3/icons/lib/light/Video'
import ChatIconLight from '@ui-components-3/icons/lib/light/Message'
import VisitScheduleType from './visit-schedule-types'
import { isBoolean } from 'lodash'
import { VISIT_CHAT_VIEW, VISIT_VIDEO_VIEW } from 'routes/paths'

export enum EVisitModalities {
  phone = 'phone',
  video = 'video',
  chat = 'chat',
}

export const VisitModalityIcons = {
  [EVisitModalities.phone]: PhoneIcon,
  [EVisitModalities.video]: VideoIcon,
  [EVisitModalities.chat]: ChatIcon,
}

export const VisitModalityIconsLight = {
  [EVisitModalities.phone]: PhoneIconLight,
  [EVisitModalities.video]: VideoIconLight,
  [EVisitModalities.chat]: ChatIconLight,
}

export const VisitModalityLabels = {
  [EVisitModalities.phone]: 'Phone Call',
  [EVisitModalities.video]: 'Video Call',
  [EVisitModalities.chat]: 'Chat',
}

export const VisitModalityLabelsShort = {
  [EVisitModalities.phone]: 'Phone',
  [EVisitModalities.video]: 'Video',
  [EVisitModalities.chat]: 'Chat',
}

const createModalityOption = (modality: EVisitModalities) => ({
  value: modality,
  label: VisitModalityLabels[modality],
  Icon: VisitModalityIcons[modality],
})

const restrictedServiceTypes = (serviceType: ServiceTypes) =>
  serviceType === VisitTypes.medical || serviceType === VisitTypes.medicalNow || serviceType === VisitTypes.psychiatry

const videoOnlyLocations = ['NJ', 'WY']

const isModalityAvailableForLocation = (modality: EVisitModalities, visitType: ServiceTypes, location: string) => {
  if (videoOnlyLocations.includes(location) && modality === EVisitModalities.phone) return false
  if (modality === EVisitModalities.phone && restrictedServiceTypes(visitType)) {
    switch (location) {
      case 'MS':
        return false
      case 'IN':
        return visitType !== VisitTypes.psychiatry
      default:
        return true
    }
  }
  return true
}

const isModalityAvailableForVisitType = (modality: EVisitModalities, visitType: string) => {
  return modality === EVisitModalities.chat ? visitType === VisitTypes.therapyNow : true
}

const getVisitTypeWithScheduledType = (visitType: ServiceTypes, scheduledType: string) => {
  const isNowVisit = scheduledType === VisitScheduleType.now
  switch (visitType) {
    case VisitTypes.therapy: {
      return isNowVisit ? VisitTypes.therapyNow : visitType
    }
    case VisitTypes.medical: {
      return isNowVisit ? VisitTypes.medicalNow : visitType
    }
    default: {
      return visitType
    }
  }
}

type ModalitiyAvailabilityMap = Partial<{
  [key in keyof typeof EVisitModalities]: boolean
}>

const isModalityAvailable = (modality: EVisitModalities, availablity: ModalitiyAvailabilityMap) => {
  if (isBoolean(availablity[modality])) {
    return availablity[modality]
  }

  return true
}

export const determineModalityRestrictions = (
  {
    visitType,
    location,
    scheduledType,
  }: {
    visitType: ServiceTypes
    location: string
    scheduledType: string
  },
  availability: ModalitiyAvailabilityMap,
) => {
  const visitTypeWithScheduledType = getVisitTypeWithScheduledType(visitType, scheduledType)

  const filteredModalities = Object.values(EVisitModalities).filter(
    (m) =>
      isModalityAvailable(m, availability) &&
      isModalityAvailableForVisitType(m, visitTypeWithScheduledType) &&
      isModalityAvailableForLocation(m, visitTypeWithScheduledType, location),
  )

  return filteredModalities.map(createModalityOption)
}

export const getVisitPathByModality = (modality: EVisitModalities) => {
  switch (modality) {
    case EVisitModalities.video:
      return VISIT_VIDEO_VIEW
    case EVisitModalities.chat:
      return VISIT_CHAT_VIEW
    default:
      return null
  }
}
